import React from 'react';
import './About.css';
import founderImage from '../assets/images/founder.jpg'; // Adjust the path as necessary

const About = () => {
  return (
    <div className="about">
      <h1>About Us</h1>
      <section className="founder">
        <div className="founder-details">
          <img src={founderImage} alt="Founder Atishay Jain" className="founder-image" />
          <div className="founder-text">
            <h2>Founder</h2>
            <p>Atishay Jain, a high school rising senior, is the visionary behind the company. Despite his young age, Atishay has demonstrated remarkable dedication and innovation in the field of event management and secure payment solutions. His commitment to excellence and customer satisfaction drives our company to new heights.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;