import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4>Company</h4>
          <ul>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/coming-soon">Careers</Link></li>
            <li><Link to="/coming-soon">Press</Link></li>
            <li><Link to="/coming-soon">Blog</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Products</h4>
          <ul>
            <li><Link to="/coming-soon">Payments</Link></li>
            <li><Link to="/coming-soon">Invoice</Link></li>
            <li><Link to="/coming-soon">Events</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Resources</h4>
          <ul>
            <li><Link to="/coming-soon">Pricing</Link></li>
            <li><Link to="/coming-soon">Terms</Link></li>
            <li><Link to="/coming-soon">Privacy</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Aank Systems. All rights reserved.</p>
        <div className="social-icons">
          <a href="https://twitter.com"><i className="fab fa-twitter"></i></a>
          <a href="https://facebook.com"><i className="fab fa-facebook-f"></i></a>
          <a href="https://linkedin.com"><i className="fab fa-linkedin-in"></i></a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;